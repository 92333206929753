module.exports = [
  {
    "name": "Milk Nook Lactation Consulting Services",
    "website": "milknook.com",
    "email": "taryn@milknook.com",
    "city": "Alameda",
    "phone": "510-999-5055",
    "state": "California"
  },
  {
    "name": "Healthy Beginnings Midwifery",
    "website": "providence.org",
    "email": "socialmedia.requests@providence.org",
    "city": "Apple Valley",
    "phone": "760-242-2311",
    "state": "California"
  },
  {
    "name": "Bakersfield Birth Center",
    "website": "bakersfieldhomebirths.com",
    "email": "mamaforhumankind@aol.com",
    "city": "Bakersfield",
    "phone": "661-557-0639",
    "state": "California"
  },
  {
    "name": "Happy Milk Lactation Support",
    "website": "happymilk.us",
    "email": "ellen@happymilk.us",
    "city": "Berkeley",
    "phone": "510-730-2154",
    "state": "California"
  },
  {
    "name": "Lynn Thiebaud Lactation Services",
    "website": "lynnthiebaudlactationservices.com",
    "email": "help@lynnthiebaudlactationservices.com",
    "city": "Berkeley",
    "phone": "510-529-5514",
    "state": "California"
  },
  {
    "name": "Best Milk LA",
    "website": "bestmilkla.com",
    "email": "bestmilkla@gmail.com",
    "city": "Beverly Hills",
    "phone": "310-729-3811",
    "state": "California"
  },
  {
    "name": "Baby Brilliance",
    "website": "babybrilliance.net",
    "email": "katie@babybrilliance.net",
    "city": "Beverly Hills",
    "phone": "480-888-6204",
    "state": "California"
  },
  {
    "name": "Healthy Horizons Peninsula Breastfeeding Center",
    "website": "healthyhorizonsonline.com",
    "email": "Info@hh-bc.com",
    "city": "Burlingame",
    "phone": "650-347-6455",
    "state": "California"
  },
  {
    "name": "Chico Pediatrics",
    "website": "chicopediatrics.com",
    "email": "contact@chicopediatrics.com",
    "city": "Chico",
    "phone": "530-343-8522",
    "state": "California"
  },
  {
    "name": "Lactation Essentials: Jennifer Lynn Haning Schreiber DBA",
    "website": "lactationessentials.com",
    "email": "breastfeedingmom@icloud.com",
    "city": "Clovis",
    "phone": "559-287-5150",
    "state": "California"
  },
  {
    "name": "Just Natural Breastfeeding Solutions",
    "website": "justnaturalbreastfeeding.com",
    "email": "info@justnaturalbreastfeeding.com",
    "city": "CORONA",
    "phone": "951-394-2087",
    "state": "California"
  },
  {
    "name": "OC Lactation Consultant - Réka Morvay, IBCLC",
    "website": "oclactationconsultant.com",
    "email": "reka@oclactationconsultant.com",
    "city": "Cypress",
    "phone": "949-466-2863",
    "state": "California"
  },
  {
    "name": "Davis Milk + Swaddles Lactation Consultant",
    "website": "davismilk.com",
    "email": "gerritmulholland@gmail.com",
    "city": "Davis",
    "phone": "530-219-1940",
    "state": "California"
  },
  {
    "name": "Generation Milk Lactation Services & Support",
    "website": "generation-milk.com",
    "email": "generationmilk@icloud.com",
    "city": "El Cajon",
    "phone": "858-480-6645",
    "state": "California"
  },
  {
    "name": "Tess Johnson Lactation Services",
    "website": "tjlactation.com",
    "email": "tess.tjlactation@gmail.com",
    "city": "Fresno",
    "phone": "559-905-6635",
    "state": "California"
  },
  {
    "name": "Community Mothers Resource Center",
    "website": "communitymedical.org",
    "email": "dmuscio@communitymedical.org",
    "city": "Fresno",
    "phone": "559-459-6288",
    "state": "California"
  },
  {
    "name": "The Nest Family Resource",
    "website": "thenestfamilyresource.org",
    "email": "thenestnevadacity@gmail.com",
    "city": "Grass Valley",
    "phone": "530-637-8411",
    "state": "California"
  },
  {
    "name": "Beach Cities Lactation",
    "website": "beachcitieslactation.com",
    "email": "aileen.lactationconsultant@gmail.com",
    "city": "Huntington Beach",
    "phone": "714-232-2699",
    "state": "California"
  },
  {
    "name": "Vivien Earthman Lactation Consultant",
    "website": "socallactation.com",
    "email": "vivienrn1@cox.net",
    "city": "Irvine",
    "phone": "949-232-3605",
    "state": "California"
  },
  {
    "name": "Danielle Gauss IBCLC",
    "website": "justbreastfeeding.com",
    "email": "danielle@justbreastfeeding.com",
    "city": "Irvine",
    "phone": "714-287-1697",
    "state": "California"
  },
  {
    "name": "Beyond the Latch Lactation Support",
    "website": "beyondthelatch.weebly.com",
    "email": "info@beyondthelatchlactation.com",
    "city": "La Verne",
    "phone": "909-542-3363",
    "state": "California"
  },
  {
    "name": "Nurturing Connections. Lactation Consultant Services",
    "website": "nurturingconnections.com",
    "email": "RPeiffer@nurturingconnections.com",
    "city": "Laguna Beach",
    "phone": "734-637-7838",
    "state": "California"
  },
  {
    "name": "Orange County Breastfeeding Consultants",
    "website": "ocbreastfeeding.com",
    "email": "info@ocbreastfeeding.com",
    "city": "Laguna Beach",
    "phone": "949-456-3866",
    "state": "California"
  },
  {
    "name": "Breastfeeding Specialist",
    "website": "breastfeedingspecialist.org",
    "email": "sdgivnbak@gmail.com",
    "city": "Lakewood",
    "phone": "562-674-8500",
    "state": "California"
  },
  {
    "name": "Dawn Bower. Dawn Of Life, My Lactation Consultant",
    "website": "dawnboweribclc.com",
    "email": "mylactationconsultant@gmail.com",
    "city": "Lancaster",
    "phone": "661-916-1290",
    "state": "California"
  },
  {
    "name": "Lactation Lady 4U!",
    "website": "lactationlady4u.com",
    "email": "jreymamaof3@gmail.com",
    "city": "Long Beach",
    "phone": "562-253-1066",
    "state": "California"
  },
  {
    "name": "Ilanit Ruppin, Lactation Consultant, IBCLC",
    "website": "linkedin.com/in/ilanit-ruppin-4aa01645",
    "email": "ilanitosh@yahoo.com",
    "city": "Los Altos",
    "phone": "408-666-6270",
    "state": "California"
  },
  {
    "name": "Bay Area Lactation Consultant",
    "website": "bayarea-breastfeeding.com",
    "email": "ilanitosh@yahoo.com",
    "city": "Los Altos",
    "phone": "408-666-6270",
    "state": "California"
  },
  {
    "name": "GoldiLacts Lactation Consulting",
    "website": "goldilacts.com",
    "email": "info@goldilacts.com",
    "city": "Los Angeles",
    "phone": "805-835-2777",
    "state": "California"
  },
  {
    "name": "HelpLatch (Johanna Vandemoortele, IBCLC - Lactation Consultant)",
    "website": "helplatch.com",
    "email": "johanna@helplatch.com",
    "city": "Los Angeles",
    "phone": "310-745-0177",
    "state": "California"
  },
  {
    "name": "The LA Lactation Lady",
    "website": "lalactation.com",
    "email": "lalactation@gmail.com",
    "city": "Los Angeles",
    "phone": "616-822-1812",
    "state": "California"
  },
  {
    "name": "Lora Luczywo, IBCLC, Los Angeles Lactation",
    "website": "losangeleslactation.com",
    "email": "lora@losangeleslactation.com",
    "city": "Los Angeles",
    "phone": "310-776-5363",
    "state": "California"
  },
  {
    "name": "Cedars-Sinai Lactation Center",
    "website": "cedars-sinai.org",
    "email": "giving@cedars-sinai.org",
    "city": "Los Angeles",
    "phone": "310-423-5312, 800-233-2771",
    "state": "California"
  },
  {
    "name": "My Nursing Coach",
    "website": "mynursingcoach.com",
    "email": "melissa@mynursingcoach.com",
    "city": "Los Angeles",
    "phone": "818-431-1118",
    "state": "California"
  },
  {
    "name": "Birth Happens Naturally",
    "website": "birthhappensnaturally.com",
    "email": "Authenticbirth@gmail.com",
    "city": "Los Angeles",
    "phone": "323-334-0889",
    "state": "California"
  },
  {
    "name": "BelliBind",
    "website": "bellibind.com",
    "email": "hello@bellibind.com",
    "city": "Los Angeles",
    "phone": "310-926-8678",
    "state": "California"
  },
  {
    "name": "Happy Mama Healthy Baby Alliance",
    "website": "motherbabysupport.net",
    "email": "inquiry@motherbabysupport.net",
    "city": "Los Angeles",
    "phone": "626-388-2191",
    "state": "California"
  },
  {
    "name": "Los Gatos Lactation",
    "website": "losgatoslactation.com",
    "email": "debra@losgatoslactation.com",
    "city": "Los Gatos",
    "phone": "408-250-9773",
    "state": "California"
  },
  {
    "name": "South Bay Baby Care",
    "website": "southbaybabycare.com",
    "email": "lisa@southbaybabycare.com",
    "city": "Manhattan Beach",
    "phone": "310-650-9722",
    "state": "California"
  },
  {
    "name": "OC Lactation Station",
    "website": "oclactationstation.com",
    "email": "megan@oclactationstation.com",
    "city": "Mission Viejo",
    "phone": "949-525-8769",
    "state": "California"
  },
  {
    "name": "Modesto Lactation and Doula Services",
    "website": "modestolactationdoula.com",
    "email": "isabelibclc@Yahoo.com",
    "city": "Modesto",
    "phone": "209-918-4423",
    "state": "California"
  },
  {
    "name": "Monterey Bay Lactation",
    "website": "montereybirthcenter.com",
    "email": "info@montereybirthcenter.com",
    "city": "Monterey",
    "phone": "831-717-4723",
    "state": "California"
  },
  {
    "name": "Bellies Babies & Bosoms",
    "website": "bellies.biz",
    "email": "info@bellies.biz",
    "city": "Montrose",
    "phone": "818-541-1200",
    "state": "California"
  },
  {
    "name": "Breast Feeding Support Morgan Hill",
    "website": "breastfeedingsupportmorganhill.com",
    "email": "breastfeedingsupportmorganhill@aol.com",
    "city": "Morgan Hill",
    "phone": "408-818-0005",
    "state": "California"
  },
  {
    "name": "Breast4Baby, Kristen Davis IBCLC, Lactation Consultant",
    "website": "breast4baby.com",
    "email": "help@breast4baby.com",
    "city": "Murrieta",
    "phone": "951-400-5536",
    "state": "California"
  },
  {
    "name": "JA Lactation Support, IBCLC",
    "website": "jalactation.com",
    "email": "julie@jalactation.com",
    "city": "Murrieta",
    "phone": "951-526-6870",
    "state": "California"
  },
  {
    "name": "JA Lactation Support, IBCLC",
    "website": "jalactation.com",
    "email": "julie@jalactation.com",
    "city": "Murrieta",
    "phone": "951-526-6870",
    "state": "California"
  },
  {
    "name": "SCV Birth Center",
    "website": "scvbirthcenter.com",
    "email": "staff@scvbirthcenter.com",
    "city": "Newhall",
    "phone": "661-254-3000",
    "state": "California"
  },
  {
    "name": "PhDMilk - Lactation Consultant",
    "website": "phdmilk.com",
    "email": "phdmilk@gmail.com",
    "city": "Newport Beach",
    "phone": "949-424-3968",
    "state": "California"
  },
  {
    "name": "East Bay Lactation Associates",
    "website": "eastbaylactationassociates.com",
    "email": "info@eastbaylactationassociates.com",
    "city": "Oakland",
    "phone": "510-525-1155",
    "state": "California"
  },
  {
    "name": "Then Comes Baby",
    "website": "then-comes-baby.com",
    "email": "hello@then-comes-baby.com",
    "city": "Oakland",
    "phone": "510-817-2229",
    "state": "California"
  },
  {
    "name": "Bay Area Breastfeeding Support",
    "website": "bayareabreastfeedingsupport.com",
    "email": "eastbaylc@gmail.com",
    "city": "Orinda",
    "phone": "925-257-4023",
    "state": "California"
  },
  {
    "name": "IBCLC Lactation Consultant",
    "website": "havanargilah.wixsite.com",
    "email": "havanargilah@gmail.com",
    "city": "Pacific Grove",
    "phone": "--",
    "state": "California"
  },
  {
    "name": "Joanna J. Koch, RN",
    "website": "sutterhealth.org",
    "email": "social@sutterhealth.org",
    "city": "Palo Alto",
    "phone": "209-603-8524",
    "state": "California"
  },
  {
    "name": "Josée Pound, Lactation Consultant, Los Angeles",
    "website": "joseepound.com",
    "email": "joseepoundlc@gmail.com",
    "city": "Pasadena",
    "phone": "--",
    "state": "California"
  },
  {
    "name": "Nourishing Start Lactation Consultant Services",
    "website": "nourishingstart.com",
    "email": "christina@nourishingstart.com",
    "city": "Pasadena",
    "phone": "626-657-7360",
    "state": "California"
  },
  {
    "name": "Stanford Health Care - Lactation Center At Valleycare",
    "website": "valleycare.com",
    "email": "vcpatientfeedback@stanfordhealthcare.org",
    "city": "Pleasanton",
    "phone": "925-416-3598",
    "state": "California"
  },
  {
    "name": "The Mother Nurture Network",
    "website": "mothernurturenetwork.com",
    "email": "mail@mothernurturenetwork.com",
    "city": "Redondo Beach",
    "phone": "310-798-9000",
    "state": "California"
  },
  {
    "name": "Midtown Nurse Midwives / Midtown Lactation Consultants (MiLC)",
    "website": "sacramentomnm.com",
    "email": "schedule@sacramentomnm.com",
    "city": "Sacramento",
    "phone": "916-936-2229",
    "state": "California"
  },
  {
    "name": "Midtown Birth Center",
    "website": "sacramentomnm.com",
    "email": "schedule@sacramentomnm.com",
    "city": "Sacramento",
    "phone": "916-936-2229",
    "state": "California"
  },
  {
    "name": "Baby Sips: In-Home Lactation Consulting",
    "website": "babysips.com",
    "email": "support@babysips.com",
    "city": "San Carlos",
    "phone": "650-222-9747",
    "state": "California"
  },
  {
    "name": "Beyond Milk Lactation Services",
    "website": "beyondmilklactation.com",
    "email": "ariana@beyondmilklactation.com",
    "city": "San Clemente",
    "phone": "949-342-8181",
    "state": "California"
  },
  {
    "name": "Breastfeeding Consultants of San Diego, LLC",
    "website": "bfcofsd.com",
    "email": "heather@bfcofsd.com",
    "city": "San Diego",
    "phone": "619-677-2730",
    "state": "California"
  },
  {
    "name": "Breastfeeding With Grace",
    "website": "breastfeedingwithgrace.com",
    "email": "graceibclc@gmail.com",
    "city": "San Diego",
    "phone": "858-271-0913",
    "state": "California"
  },
  {
    "name": "Breastfeeding Fixers & Leilani's Wellness Center",
    "website": "breastfeedingfixers.com",
    "email": "leilanilopez1@icloud.com",
    "city": "San Diego",
    "phone": "760-533-7953",
    "state": "California"
  },
  {
    "name": "Megan Ameral Postpartum Doula",
    "website": "meganameraldoula.com",
    "email": "megan.ameral@gmail.com",
    "city": "San Diego",
    "phone": "619-672-7876",
    "state": "California"
  },
  {
    "name": "Sharp Mary Birch Hospital for Women & Newborns New Beginnings Boutique & Gift Shop",
    "website": "sharp.com",
    "email": "feedback@sharp.com",
    "city": "San Diego",
    "phone": "858-939-4127, 800-827-4277",
    "state": "California"
  },
  {
    "name": "Amber Burvall, Lactation Consultant San Diego",
    "website": "sandiegobreastfeedingsupport.com",
    "email": "amberburvall@hotmail.com",
    "city": "San Diego ",
    "phone": "619-944-4772",
    "state": "California"
  },
  {
    "name": "Katie Howser, IBCLC, Lactation Consultant",
    "website": "katiehowser.com",
    "email": "hello@katiehowser.com",
    "city": "San Francisco",
    "phone": "415-969-0264",
    "state": "California"
  },
  {
    "name": "Call the LC - Lactation Consultant Services",
    "website": "callthelc.com",
    "email": "hello@callthelc.com",
    "city": "San Francisco",
    "phone": "415-420-4835",
    "state": "California"
  },
  {
    "name": "Jennifer Suffin, IBCLC, RLC - Lactation and Breastfeeding Consultant",
    "website": "careinthecurve.com",
    "email": "jenn@careinthecurve.com",
    "city": "San Francisco",
    "phone": "415-939-7877",
    "state": "California"
  },
  {
    "name": "Jen Kiatta IBCLC",
    "website": "jenkiatta.com",
    "email": "YOGLA@JENKIATTA.COM",
    "city": "San Francisco",
    "phone": "917-445-7110",
    "state": "California"
  },
  {
    "name": "Blue Ova Health & Acupuncture San Francisco",
    "website": "blueova.com",
    "email": "info@blueova.com",
    "city": "San Francisco",
    "phone": "415-795-3900",
    "state": "California"
  },
  {
    "name": "Sarah Quigley Lactation Support",
    "website": "sarahquigley.com",
    "email": "info@sarahquigley.com",
    "city": "San Francisco",
    "phone": "415-745-8314",
    "state": "California"
  },
  {
    "name": "Caroline Kerhervé",
    "website": "carolinekerherve.com",
    "email": "contact@carolinekerherve.com",
    "city": "San Francisco",
    "phone": "415-867-6873",
    "state": "California"
  },
  {
    "name": "Babe N' You",
    "website": "babenyou.com",
    "email": "sarah@babenyou.com",
    "city": "San Francisco Bay Area",
    "phone": "206-601-2976",
    "state": "California"
  },
  {
    "name": "Farrell Topham, IBCLC",
    "website": "breastfeedingsf.com",
    "email": "farrell@breastfeedingsf.com",
    "city": "San Franscisco",
    "phone": "415-509-6300",
    "state": "California"
  },
  {
    "name": "Breastfeeding Your Way-Tali Ganir, Lactation Consultant, IBCLC",
    "website": "taliganir.com",
    "email": "info@taliganir.com",
    "city": "San Jose",
    "phone": "408-338-7864",
    "state": "California"
  },
  {
    "name": "Hot Milk Breastfeeding LLC",
    "website": "hotmilkbreastfeeding.com",
    "email": "sboudrie@hotmilkbreastfeeding.com",
    "city": "San Jose",
    "phone": "734-652-2839",
    "state": "California"
  },
  {
    "name": "Love in Leche - Teresa Munguia, IBCLC Lactation Consultant",
    "website": "loveinleche.com",
    "email": "teresaibclc@loveinleche.com",
    "city": "San Jose",
    "phone": "408-821-4462",
    "state": "California"
  },
  {
    "name": "Lactation Lu Lu",
    "website": "lactationlulu.com",
    "email": "lindsey@lactationlulu.com",
    "city": "San Luis Obispo",
    "phone": "805-748-7541",
    "state": "California"
  },
  {
    "name": "Milk Made | Lactation Consulting | Kelly Ryan, IBCLC",
    "website": "milkmadeca.com",
    "email": "kelly@milkmadeca.com",
    "city": "San Mateo",
    "phone": "650-867-6752",
    "state": "California"
  },
  {
    "name": "Tender Bees Doula Services",
    "website": "tenderbeesdoula.com",
    "email": "cali@tenderbeesdoula.com",
    "city": "Santa Clarita",
    "phone": "661-309-2955",
    "state": "California"
  },
  {
    "name": "The Pump Station & Nurtury - Santa Monica",
    "website": "pumpstation.com",
    "email": "info@pumpstation.com",
    "city": "Santa Monica",
    "phone": "310-998-1981",
    "state": "California"
  },
  {
    "name": "Hearthstone Midwifery, Lactation & Craniosacral Services",
    "website": "hearthstonemidwifery.com",
    "email": "Rebecca@HearthstoneMidwifery.com",
    "city": "Sebastopol",
    "phone": "707-893-7877",
    "state": "California"
  },
  {
    "name": "Mother Infant Lactation Center",
    "website": "milconline.com",
    "email": "roza@milconline.com",
    "city": "Sherman Oaks",
    "phone": "818-913-2228",
    "state": "California"
  },
  {
    "name": "Jess with Lactation Link",
    "website": "lactationlink.com",
    "email": "jessicam@lactationlink.com",
    "city": "Simi Valley",
    "phone": "805-390-0911",
    "state": "California"
  },
  {
    "name": "Pump House",
    "website": "thepumphouse.com.co",
    "email": "abbiehoag@yahoo.com",
    "city": "Vacaville",
    "phone": "707-446-8959",
    "state": "California"
  },
  {
    "name": "Pure Births",
    "website": "purebirths.com",
    "email": "info@purebirths.com",
    "city": "VALENCIA",
    "phone": "661-505-8370",
    "state": "California"
  },
  {
    "name": "Green Hugs",
    "website": "greenhugs.net",
    "email": "rachel@greenhugs.net",
    "city": "Van Nuys",
    "phone": "818-636-2223",
    "state": "California"
  },
  {
    "name": "Babies in Bloom",
    "website": "babies-in-bloom.com",
    "email": "alison@babies-in-bloom.com",
    "city": "Vista",
    "phone": "760-940-2229",
    "state": "California"
  },
  {
    "name": "Paula Santi Lactation",
    "website": "paulasanti.com",
    "email": "paula@paulasanti.com",
    "city": "Walnut Creek",
    "phone": "510-750-2255",
    "state": "California"
  },
  {
    "name": "Linda Goodman IBCLC, Lactation Consultant",
    "website": "lindagoodman.weebly.com",
    "email": "LindaGoodmanIBCLC@yahoo.com",
    "city": "Whittier",
    "phone": "562-231-6455",
    "state": "California"
  }
]
