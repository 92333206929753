import React from "react";
import Layout from "../components/layout";
import doulasStyles from "./doulas.module.css";
import {Container, Row, Col} from "react-bootstrap";
import globalStyle from "../styles/global.module.css";
import lactationConsultantsList from "../config/lactation-consultants";
import ContactUs from '../components/contact-us';
import MeetProfessionals from '../components/meet-professionals';


const LactationConsultantsPage = () => {
  return (
    <Layout className="site-content">
      <Container fluid className={doulasStyles.containerStyle}>
        <Row className="workshop">
          <Col className={doulasStyles.largeTitle}>
            <h1 className={globalStyle.entryTitle}>MEET THE <span>LACTATION CONSULTANTS</span></h1>
          </Col>
        </Row>

        <MeetProfessionals professionalsList={lactationConsultantsList}></MeetProfessionals>
        <ContactUs></ContactUs>

      </Container>
    </Layout>
  )
};

export default LactationConsultantsPage
